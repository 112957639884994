<template>
    <ConfirmDialog group="dialog" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>{{ $t('Applications.Details.Heading') }}</strong>
                </h4>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.Title')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ title }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.Description')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ description }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.Country')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.MaxApplicants')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ max_user_apply }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.noofApplicants')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicantCount }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.Noofselectedapplicants')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ selectedApplicantCount }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.ApplicationStatus')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <span
                            v-if="cur_date > startdateformat(expire_date) && cur_date < startdateformat(voting_start_date)">
                            <p>Shortlisting Ongoing</p>
                        </span>

                        <span
                            v-else-if="cur_date >= startdateformat(voting_start_date) && cur_date <= startdateformat(voting_end_date)">
                            <p>Voting Ongoing</p>
                        </span>

                        <span
                            v-else-if="cur_date >= startdateformat(release_date) && cur_date <= startdateformat(expire_date)">
                            <p>Registration Ongoing</p>
                        </span>

                        <span v-else-if="cur_date > startdateformat(expire_date)">
                            <p>Expired</p>
                        </span>

                        <span v-else-if="cur_date < startdateformat(release_date)">
                            <p>Yet to Start</p>
                        </span>

                        <span v-else>
                            <p>--</p>
                        </span>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.ApplicationStartDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(release_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.ApplicationEndDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(expire_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.VotingstartDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(voting_start_date) }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Applications.Details.VotingEndDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ UTCdateformat(voting_end_date) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="p-grid p-fluid">
            <div class="p-col-12">
                <h5>{{ $t('Applications.Applicants.search') }}</h5>
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="type">{{ $t('Applications.Applicants.Name') }}</label>
                        <InputText id="googlurl" type="text" :placeholder="$t('Applications.Applicants.SBName')"
                            v-model="name" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('Applications.Applicants.Email') }}</label>
                        <InputText id="googlurl" type="text" :placeholder="$t('Applications.Applicants.SBEmail')"
                            v-model="email" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Age_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Age_From')" v-model="age_from" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Age_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                            :placeholder="$t('ApplicationManagement.view.Search_by_Age_To')" v-model="age_to" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="type">{{ $t('Applications.Applicants.Gender') }}</label>
                        <Dropdown v-model="gender" :options="dropdownGenderValues" optionValue="value"
                            optionLabel="name" :placeholder="$t('Applications.Applicants.Select')" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Height_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                        :placeholder="$t('ApplicationManagement.view.Search_by_Height_From')" v-model="height_from" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Height_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                        :placeholder="$t('ApplicationManagement.view.Search_by_Height_To')" v-model="height_to" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Weight_From') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                        :placeholder="$t('ApplicationManagement.view.Search_by_Weight_From')" v-model="weight_from" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('ApplicationManagement.view.Weight_To') }}</label>
                        <InputText id="googlurl" type="text" onkeypress="return /[0-9]/i.test(event.key)"
                        :placeholder="$t('ApplicationManagement.view.Search_by_Weight_To')" v-model="weight_to" />
                    </div>

                    <!-- <div class="p-field p-col-12 p-md-3">
                        <label for="pass">Applicant Selection Status</label>
                        <Dropdown v-model="is_shortlisted" :options="dropdownAppStatusValues" optionValue="value"
                            optionLabel="name" placeholder="Select Status" />
                    </div> -->

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">{{ $t('Applications.Applicants.RegistrationDate') }}</label>
                        <Calendar inputId="range" v-model="dateVotingRange" selectionMode="range" :manualInput="false"
                            placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
            <div class="p-mb-4 p-mb-lg-0"></div>
            <div>
                <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchvotemanagement()"></Button>
                <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="resetvotemanagement()"></Button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <h4>{{ $t('Applications.Applicants.Heading') }}</h4>
                    <div>
                        <!-- <router-link to="/VotemanagementAdd"> -->
                        <Button :label="$t('Applications.Applicants.Winners')" icon="pi pi-flag" iconPos="left"
                            class="p-button p-button-sm p-mr-2 p-mb-2" @click="ViewWinners" v-if="Applicants.length != 0"></Button>
                        <!-- </router-link> -->
                    </div>
                </div>
                <div v-if="Applicants.length === 0" style="display:flex; justify-content:center;">
                    <p><b>{{$t('Nodata')}}</b></p>
                </div>
                <DataTable :value="Applicants" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" v-else>
                    <ConfirmDialog group="dialog" />

                    <template #empty>{{$t('Nodata')}}</template>
                    <template #loading>Loading data. wait.</template>

                    <Column field="#" header="#" style="min-width: 5rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Sl. No.</span>
                            {{ data.serial_no }}
                        </template>
                    </Column>
                    <Column field="Applicant ID" :header="$t('Applications.Applicants.ApplicantID')"
                        style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Applicant ID</span>
                            {{ data.applicant_id }}
                        </template>
                    </Column>
                    <Column field="Name" :header="$t('Applications.Applicants.Name')" style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Name</span>
                            {{ data.name }}
                        </template>
                    </Column>
                    <Column field="Age" :header="$t('Applications.Applicants.Age')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Age</span>
                            {{ data.age }}
                        </template>
                    </Column>
                    <Column field="Gender" :header="$t('Applications.Applicants.Gender')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Gender</span>

                            {{ data.gender === 'm' ? 'Male' : 'Female' }}
                        </template>
                    </Column>

                    <Column field="Email" :header="$t('Applications.Applicants.Email')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Email</span>
                            {{ data.email }}
                        </template>
                    </Column>

                    <Column field="Mobile Number" :header="$t('Applications.Applicants.MobileNumber')"
                        style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Mobile Number</span>
                            {{ data.mobile }}
                        </template>
                    </Column>

                    <Column field="Height" :header="$t('Applications.Applicants.Height')" style="min-width: 13rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Height</span>
                            {{ data.height }}
                        </template>
                    </Column>

                    <Column field="Weight" :header="$t('Applications.Applicants.Weight')" style="min-width: 12rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Weight</span>
                            {{ data.weight === null ? '--' : data.weight }}
                        </template>
                    </Column>

                    <Column field="Country" :header="$t('Applications.Applicants.Country')" style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Country</span>
                            {{ data.country_name }}
                        </template>
                    </Column>
                    <Column field="Registration Date" :header="$t('Applications.Applicants.RegistrationDate')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Registration Date</span>
                            {{ Regdateformat(data.registration_date) }}
                        </template>
                    </Column>
                    <Column field="Vote Count" :header="$t('Applications.Applicants.VoteCount')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Vote Count</span>
                            {{ data.vote_count }}
                        </template>
                    </Column>
                    <Column field="Selection" :header="$t('Applications.Applicants.Selection')"
                        style="min-width: 14rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Selection</span>
                            <span v-if="data.is_shortlisted == 1">
                                <i class="pi pi-check" id="sign"></i>
                            </span>
                            <span v-else-if="data.is_shortlisted == 0">
                                <i class="pi pi-times" id="sign"></i>
                            </span>
                            <span v-else>
                                <i class="pi pi-check" disabled id="signs"></i>
                            </span>
                        </template>
                    </Column>

                    <Column field="Actions" :header="$t('Applications.Applicants.Action')">
                        <template #body="{ data }">
                            <span class="p-column-title">Actions</span>
                            <p style="display: none">{{ data.status }}</p>
                            <div style="display: flex">
                                <router-link :to="'/ViewApplicants/' + data.id">
                                    <Button label="info" class="n-wrap p-button-outlined p-button-info p-mb-2">
                                        <i class="pi pi-eye p-mr-2"></i>
                                    </Button>
                                </router-link>
                                <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <div class="p-d-flex p-jc-end" style="float: left">
        <span v-if="cur_date > startdateformat(expire_date) && cur_date < startdateformat(voting_start_date)">
            <Button :label="$t('Applications.Applicants.Edit')"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left"
                disabled></Button>
        </span>

        <span
            v-else-if="cur_date >= startdateformat(voting_start_date) && cur_date <= startdateformat(voting_end_date)">
            <Button :label="$t('Applications.Applicants.Edit')"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left"
                disabled></Button>
        </span>

        <span v-else-if="cur_date >= startdateformat(release_date) && cur_date <= startdateformat(expire_date)">
            <Button :label="$t('Applications.Applicants.Edit')"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left"
                disabled></Button>
        </span>

        <span v-else-if="cur_date > startdateformat(expire_date)">
            <Button :label="$t('Applications.Applicants.Edit')"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left"
                disabled></Button>
        </span>

        <span v-else-if="cur_date < startdateformat(release_date)">
            <router-link :to="'/VoteManagementEdit/' + $route.params.id"><Button
                    :label="$t('Applications.Applicants.Edit')"
                    class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left">
                </Button></router-link>
        </span>

        <span v-else>
            <router-link :to="'/VoteManagementEdit/' + $route.params.id"><Button
                    :label="$t('Applications.Applicants.Edit')"
                    class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil" iconPos="left">
                </Button></router-link>
        </span>

        <!-- <Button @click="del($route.params.id)" label="delete" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"><i class="pi pi-trash p-mr-2"></i>{{$t('button.delete')}}</Button> -->
    </div>

    <div class="p-d-flex p-jc-end p-ai-center">
        <div>
            <Button :label="$t('Applications.Applicants.Back')" icon="pi pi-replay" iconPos="left"
                class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
// import {useRouter} from 'vue-router'
import moment from 'moment';
export default {
    data() {
        return {
            dropdownGenderValues: [
                { name: '남성', value: 'm' },
                { name: '여성', value: 'f' },
            ],
            dropdownGenderValue: null,
            dropdownAppStatusValues: [
                { name: 'Pending', value: '2' },
                { name: 'Approve', value: '1' },
                { name: 'Rejected', value: '0' },
            ],
            dropdownAppStatusValue: null,
            title: '',
            country_id: '',
            max_user_apply: '',
            image: '',
            description: '',
            release_date: '',
            expire_date: '',
            voting_start_date: '',
            voting_end_date: '',
            status: 'active',
            selectedApplicantCount: '',
            applicantCount: '',

            file: '',
            files: '',
            fileName: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            name: '',
            email: '',
            age_from: '',
            age_to: '',
            gender: '',
            height_from: '',
            height_to: '',
            weight_from: '',
            weight_to: '',
            from_date: '',
            to_date: '',
            applicationStatus: '',
            registrationDate: '',
            application_id: '',
            dateVotingRange: '',
            Applicants: [],
            applicantId: '',
            is_shortlisted: '',
            vote_count: '',
            serial_no: '',
            application_status: '',
            // type:''
            cur_date: moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
        };
    },
    created() {
        this.votemanagementService = new VotemanagementService();
    },

    mounted() {
        this.getApplicantList();
        this.votemanagementService.viewVoteManagement(this.$route.params.id).then((res) => {
            this.title = res.data.data.application[0].title;
            this.country_name = res.data.data.application[0].country_name;
            this.voting_end_date = this.FormatDateAdd(res.data.data.application[0].voting_end_date);
            this.voting_start_date = this.FormatDateAdd(res.data.data.application[0].voting_start_date);
            this.status = res.data.data.application[0].status;
            this.expire_date = this.FormatDateAdd(res.data.data.application[0].expire_date);
            this.description = res.data.data.application[0].description;
            this.release_date = this.FormatDateAdd(res.data.data.application[0].release_date);
            this.max_user_apply = res.data.data.application[0].max_user_apply;
            this.applicantCount = res.data.data.applicantCount;
            this.selectedApplicantCount = res.data.data.selectedApplicantCount;
            this.application_status = res.data.data.application[0].application_status;
        });
    },
    methods: {
        startdateformat(value) {
            if (value) {
                return moment.utc(value).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        Regdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD ');
                return moment(String(value)).locale('ko').format('ll');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },
        FormatDateAdd(value) {
            if (value) {
                return moment.utc(value).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        UTCdateformat(value) {
            if (value) {
                // return moment.utc(value).format('YYYY/MM/DD HH:mm:ss ');
                return moment.utc(value).locale('ko').format('ll - LTS');
            }
        },

        getApplicantList() {
            const ApplicantParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                application_id: this.$route.params.id,
                name: this.name,
                email: this.email,
                age_from: this.age_from,
                age_to: this.age_to,
                weight_from: this.weight_from,
                weight_to: this.weight_to,
                height_from: this.height_from,
                height_to: this.height_to,
                gender: this.gender,
                applicationStatus: this.applicationStatus,
                from_date: this.searchdate,
                to_date: this.searchenddate,
                is_shortlisted: this.is_shortlisted,
            };
            this.loading1 = true;
            this.votemanagementService
                .getParticularVoteApplicatList(ApplicantParams)
                .then((res) => {
                    this.Applicants = res.data.data.applicants;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },
        ViewWinners() {
            const ApplicantParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                application_id: this.$route.params.id,
                name: this.name,
                email: this.email,
                age_from: this.age_from,
                age_to: this.age_to,
                weight_from: this.weight_from,
                weight_to: this.weight_to,
                height_from: this.height_from,
                height_to: this.height_to,
                gender: this.gender,
                applicationStatus: this.applicationStatus,
                from_date: this.searchdate,
                to_date: this.searchenddate,
                is_shortlisted: this.is_shortlisted,
            };
            this.loading1 = true;
            this.votemanagementService
                .getWinnerList(ApplicantParams)
                .then((res) => {
                    this.Applicants = res.data.data.applicants;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },

        resetvotemanagement() {
            this.name = '';
            this.email = '';
            this.age_from = '';
            this.age_to = '';
            this.height_from = '';
            this.height_to = '';
            this.weight_from = '';
            this.weight_to = '';
            this.gender = '';
            this.applicationStatus = '';
            this.dateVotingRange = '';
            this.is_shortlisted = '';
            // this.calendarValue1 = '';
            this.loading1 = true;
            // this.getApplicantList();
            window.location.reload();
        },

        searchvotemanagement() {
            if (
                this.name === '' &&
                this.email === '' &&
                this.dateVotingRange === '' &&
                this.age_from === '' &&
                this.age_to === '' &&
                this.weight_from === '' &&
                this.weight_to === '' &&
                this.height_from === '' &&
                this.height_to === '' &&
                this.applicationStatus === '' &&
                this.gender === '' &&
                this.is_shortlisted === ''
            ) {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.dateVotingRange.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.dateVotingRange.at(0));
                } else {
                    this.searchdate = '';
                }
                if (this.dateVotingRange.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.dateVotingRange.at(1));
                } else {
                    this.searchenddate = '';
                }
                const ShortlistParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    application_id: this.$route.params.id,
                    name: this.name,
                    email: this.email,
                    age_from: this.age_from,
                    age_to: this.age_to,
                    weight_from: this.weight_from,
                    weight_to: this.weight_to,
                    height_from: this.height_from,
                    height_to: this.height_to,
                    gender: this.gender,
                    applicationStatus: this.applicationStatus,
                    from_date: this.searchdate,
                    to_date: this.searchenddate,
                    is_shortlisted: this.is_shortlisted,
                };
                this.votemanagementService
                    .getParticularVoteApplicatList(ShortlistParams)
                    .then((res) => {
                        this.Applicants = res.data.data.applicants;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.Applicants = [];
                        this.loading1 = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
    /* margin-left: 25%; */
}

i#sign {
    background: black;
    color: white;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

i#signs {
    background: grey;
    color: rgb(243, 235, 235);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
</style>